import React, {Component} from 'react';

class MessageTab extends Component {
    constructor(props) {
        super(props);

        this.setActiveMessage = this.setActiveMessage.bind(this);
    }

    setActiveMessage(e) {
        let self = this;
        let message = e.target.closest('.message-tab');
        message.classList.add('active');
        self.props.onMessageSelect(message.dataset.id);
        //TODO handle setting messages read and getting rid of star
    }

    render() {
        let self = this;
        let isActive = self.props.isActive ? ' active' : '';

        return (
            <div className={"message-tab" + isActive} data-id={self.props.messageId} onClick={self.setActiveMessage}>
                <span className="fas fa-star"></span>
                {self.props.subject &&
                    <p>Subject: {self.props.subject}</p>
                }
                {!self.props.isTutor && self.props.tutor &&
                    <p>Tutor: {self.props.tutor.name}</p>
                }
                {self.props.isTutor && self.props.student.family &&
                    <p>Family: {self.props.student.family.user.last_name},  {self.props.student.family.user.first_name}</p>
                }
                {self.props.student &&
                    <p>Student: {self.props.student.name}</p>
                }
            </div>
        )
    }
}

export default MessageTab;