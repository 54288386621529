import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from './Link';
import getSeason from './GetSeason';
import CustomSelect from 'vanilla-js-dropdown';
import 'vanilla-js-dropdown/vanilla-js-dropdown.css';

const WEBSITE_URL = require('./Urls').WEBSITE_URL;

class SidebarLeft extends Component {
    componentDidMount() {
        let self = this;
        window.addEventListener('scroll', function(){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let sidebar = document.getElementById('sidebarLeft');
            let sidebarNav = document.getElementById('sidebarNav');
            if (sidebar) {
                if (scrollTop > 250) {
                    sidebar.style.position = 'fixed';
                    sidebar.style.top = 0;
                    sidebarNav.style.height = '100vh';
                } else {
                    sidebar.style.position = 'relative';
                }
            }
        });
        let testNav = document.getElementById('test-nav');
        if (testNav) {
            new CustomSelect({
                elem: testNav,  // id of the original select element
            });
            testNav.addEventListener('change', function(){
                self.props.appProps.history.push('/test-prep/' + this.value + '/practice-questions');
                document.querySelector('.js-Dropdown button').innerHTML = 'Select a Test';
            });
        }

    }

    toggleLeftSidebar(e)
    {
        let sidebar = document.getElementById("sidebarLeft");
        if (sidebar) {
            if (e.target && e.target.id === 'expand-left') {
                if (sidebar.classList.contains("open")) {
                    sidebar.classList.remove("open");
                    sidebar.style.left = '-260px';
                } else {
                    sidebar.classList.add("open");
                    sidebar.style.left = '0';
                }
            }
        }
    }

    render() {
        let self = this;
        let children = [];
        if (this.props.user.children.length) {
            children = this.props.user.children;
        }
        let i = -1;
        let j = 0;
        let gogoLink = 'https://gogobrain.com/?app=1';
        if (this.props.user.gogo_token) {
            gogoLink = 'https://app.gogobrain.com/?app=1';
        }
        let iqfpLink = WEBSITE_URL + 'store/iqfunpack';
        if (this.props.user.paid_access || this.props.user.iqfunpack) {
            iqfpLink = WEBSITE_URL + 'iqfunpack';
        }

        let season = getSeason();
        let enrichmentText = season + ' Enrichment';
        let hasKindergarten = false;
        if (this.props.user.children.length > 0) {
            for (let child in this.props.user.children) {
                if (this.props.user.children[child].grade === 1 || this.props.user.children[child].grade === 2) {
                    hasKindergarten = true;
                }
            }
        }

        let show_free_questions = false;
        // 14400000 = 4 hours in milliseconds
        if (!this.props.user.paid_access && !this.props.user.is_staff &&
            new Date() - new Date(this.props.user.date_joined) < 14400000) {
            show_free_questions = true;
        }

        return (
            <div className="sidebar" id="sidebarLeft">
                <nav id="sidebarNav" onClick={this.toggleLeftSidebar}>
                    <select id="test-nav" name={"test-nav"} defaultValue={1}>
                        <option value={1} disabled>Select a Test</option>
                        {this.props.tests && Object.keys(this.props.tests).map(function(test){
                            j++;
                            return (
                                <option key={j} value={test}>{self.props.tests[test].title}</option>
                            );
                        })}
                    </select>
                    <ul>
                        <li>
                            <Link to="/" className={this.props.location.pathname === '/' ? 'active' : ''}>
                                <span className="svg"><FontAwesomeIcon icon="home" /></span>
                                <span>Home</span>
                            </Link>
                        </li>
                        {
                            children.map(function(child) {
                                i++;
                                let avatar_url = '/img/avatars/ava_logo.png';
                                if (child.avatar) {
                                    avatar_url = '/img/avatars/' + child.avatar + '.png';
                                }
                                let has_digital_tutor = false;
                                if (child.hasOwnProperty('tests') && child.tests.length) {
                                    has_digital_tutor = true;
                                }
                                let link = "/dashboard/" + i;
                                let linkClass = 'child ';
                                if (self.props.location.pathname.indexOf(link) !== -1) {
                                    linkClass += 'active';
                                }
                                if (has_digital_tutor) {
                                    link = "/dashboard/" + i;
                                } else {
                                    link = "/dashboard/" + i + "/edit-child/";
                                }
                                return (
                                    <li key={i}>
                                    <Link to={link} className={linkClass}>
                                        <span className="avatar"><img src={avatar_url} alt=""/></span>
                                        <span>{child.name}</span>
                                    </Link>
                                    </li>
                                );
                            })
                        }
                        {!this.props.user.children.hasOwnProperty(this.props.user.active_child) &&
                        this.props.user.children.length < 1 &&
                        <li>
                            <Link to={"/add-child"}>
                                <span className="avatar add-student"><FontAwesomeIcon icon={"plus-circle"} /></span>
                                <span>Add Student</span>
                            </Link>
                        </li>
                        }
                        <li className="break" />
                        {!this.props.user.paid_access && !this.props.user.is_staff &&
                            <li>
                                <a href={WEBSITE_URL + 'buy-now/'} className={"buy-now"}>
                                    <img src='/img/test-dashboard/button-buy-now-sidebar.png' alt='Buy Now'
                                         className="buy-now-sidebar" />
                                </a>
                            </li>
                        }
                        {!this.props.user.paid_access && !this.props.user.is_staff &&
                        <li className="break"/>
                        }
                        <li className={'test-prep'}>
                            <Link to="/tests" className={this.props.location.pathname.indexOf('/test') !== -1 ? 'active' : ''}>
                                <span className="svg"/>
                                <span>Test Prep</span>
                            </Link>
                        </li>
                        {(this.props.user.tutoring_client || this.props.user.tutor) &&
                        <li className={'tutoring'}>
                            <Link to="/tutoring"
                                  className={this.props.location.pathname.indexOf('/tutoring') !== -1 ? 'active' : ''}>
                                <span className="svg"/>
                                <span>Tutoring</span>
                            </Link>
                            <ul className={this.props.location.pathname.indexOf('/tutoring') !== -1 ? 'active' : ''}>
                                <li>
                                    <Link to={"/tutoring"}
                                          className={(this.props.location.pathname.indexOf('/calendar') === -1 && this.props.location.pathname.indexOf('/inbox') === -1) ? 'active' : ''}>
                                        Overview
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/tutoring/calendar"}
                                          className={this.props.location.pathname.indexOf('/calendar') !== -1 ? 'active' : ''}>
                                        {this.props.user.tutor ? 'View My Schedule' : 'Schedule My Sessions'}
                                    </Link>
                                </li>
                                {!this.props.user.tutor &&
                                <li>
                                    <Link to={WEBSITE_URL + "kids"}>Purchase More Sessions</Link>
                                </li>
                                }
                                {this.props.user.tutor &&
                                <li>
                                    <Link to={WEBSITE_URL + "crm?tutoring=1&tutor-invoices=1"}>My Invoices</Link>
                                </li>
                                }
                                <li>
                                    <Link to={"/tutoring/inbox"}
                                          className={this.props.location.pathname.indexOf('/inbox') !== -1 ? 'active' : ''}>
                                        My Inbox
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        }
                        <li className={'sba'}>
                            <Link to="/skill-building-academy" className={
                                this.props.location.pathname.indexOf('/skill-building-academy') !== -1 ? 'active' : ''
                            }>
                                <span className="svg"/>
                                <span>Interactive Learning Apps</span>
                            </Link>
                        </li>
                        <li className={'games'}>
                            <Link to="/games" className={
                                this.props.location.pathname.indexOf('/games') !== -1 ? 'active' : ''
                            }>
                                <span className="svg"/>
                                <span>Test Prep Games</span>
                            </Link>
                            <ul>
                                <li>
                                    <a href="/tests/cogat">
                                        Analogy Galaxy Junior
                                    </a>
                                    <a href="/">
                                        Folding Questions
                                    </a>
                                    <a href={WEBSITE_URL + 'iqfuntree'}>
                                        IQ Fun Tree
                                    </a>
                                    <a href="/">
                                        Pattern Blast
                                    </a>
                                    <a href={WEBSITE_URL + 'pattern-completion'}>
                                        Pattern Puzzlers
                                    </a>
                                    <a href={WEBSITE_URL + 'pattern-tiles-advanced-beta'}>
                                        Pattern Tiles Galaxy
                                    </a>
                                    <a href="/">
                                        Planet Pattern Tiles
                                    </a>
                                </li>
                            </ul>
                        </li>
                        {!this.props.user.tutoring_client && !this.props.user.tutor &&
                        <li className={'tutoring'}>
                            <Link to={WEBSITE_URL + "kids" }
                                className={this.props.location.pathname.indexOf('/tutoring') !== -1 ? 'active' : ''}>
                                <span className="svg"/>
                                <span>Tutoring</span>
                            </Link>
                        </li>
                        }
                        <li className={'classes'}>
                            <Link to={WEBSITE_URL + 'classes/'}>
                                <span className="svg"/>
                                <span>On-Demand Classes &amp; Recorded Webinars</span>
                            </Link>
                        </li>
                        <li className={'resources'}>
                            <Link to="/parent-resources" className={this.props.location.pathname.indexOf('/parent-resources') !== -1 ? 'active' : ''}>
                                <span className="svg"/>
                                <span>Parent Resources</span>
                            </Link>
                        </li>
                        <li className={'gogo'}>
                            <Link to={gogoLink} target={'_blank'}>
                                <span className="svg"/>
                                <span>GoGoBrain</span>
                            </Link>
                        </li>
                        <li className={'store'}>
                            <a href={WEBSITE_URL + 'store'}>
                                <span className="svg"/>
                                <span>Store</span>
                            </a>
                        </li>
                        {hasKindergarten === true &&
                        <li className={'kindergarten'}>
                            <Link to="/test-prep/kindergarten-readiness"
                                  className={this.props.location.pathname.indexOf('kindergarten-readiness') !== -1 ? 'active' : ''}>
                                <span className="svg"/>
                                <span style={{fontSize: '0.95em'}}>Kindergarten Readiness</span>
                            </Link>
                        </li>
                        }
                        <li className={'enrichment ' + season}>
                            <Link to="/enrichment" className={
                                this.props.location.pathname.indexOf('enrichment') !== -1 ? 'active' : ''
                            }>
                                <span className="svg"/>
                                <span>{enrichmentText}</span>
                            </Link>
                        </li>
                        <li className={'coding-academy'}>
                            {this.props.user.has_class_subscription &&
                            <Link to={WEBSITE_URL + 'camps/?categories=28'}>
                                <span className="svg"/>
                                <span>Coding Academy</span>
                            </Link>
                            }
                            {!this.props.user.has_class_subscription &&
                            <Link to={WEBSITE_URL + 'coding-academy/'}>
                                <span className="svg"/>
                                <span>Coding Academy</span>
                            </Link>
                            }
                        </li>
                        {show_free_questions &&
                        <li className={'free-questions'}>
                            <a href={WEBSITE_URL + 'buy-now/?free-questions=1'}>
                                <span className="svg"/>
                                <span>100 Free Questions</span>
                            </a>
                        </li>
                        }
                        <li className={'tour'}>
                            <a href='/' onClick={self.props.showSiteTour}>
                                <span className="svg"/>
                                <span>Site Tour</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div id="expand-left" onClick={this.toggleLeftSidebar}>
                    <FontAwesomeIcon icon="arrow-left" onClick={this.toggleLeftSidebar} />
                    <div className="clear" />
                </div>
            </div>
        );
    }
}

export default withRouter(SidebarLeft);
