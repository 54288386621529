import React, {Component} from 'react';
import {LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, Label} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ParentDashboard.css'
import Link from '../common/Link';
import Popup from '../common/Popup';

const Loading = require('../common/Loading').Loading;
const moment = require('moment-timezone');
const ApiFetch = require('../common/ApiFetch').ApiFetch;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class ParentDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'camp_link': WEBSITE_URL + 'camps/',
            'popUpContents': '',
            'popUpClassName': ''
        };
    }

    componentDidMount() {
        let self = this;

        ApiFetch('option/?name=camp_link').then(function(res) {
            if (typeof res !== 'undefined' && res.hasOwnProperty('results') && res['results'].length && res['results'][0]['option_value']) {
                self.setState({
                    camp_link: res['results'][0]['option_value']
                });
            }
        });
    }

    viewport()
    {
        var e = window
            , a = 'inner';
        if ( !( 'innerWidth' in window ) )
        {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
    }

    render () {
        let self = this;
        let i = -1;
        let onlineClasses = "";
        let studentsClass = "";
        if (this.props.user.children.length === 0) {
            studentsClass = " noStudents";
        }
        let camp_link = this.state.camp_link;
        if (!this.props.user.paid_access) {
            camp_link = WEBSITE_URL + 'buy-now/?redirected=1&camps=1';
        }
        return (
            <div id="parent-dashboard" className={onlineClasses + studentsClass}>
                <div className="wrapper">
                    <div className="dashboard">
                        <div className="page-header child-navigation">
                            <h1>WELCOME TO TESTING MOM!</h1></div>
                        {this.props.user.children.length > 0 &&
                        <div className={'recent-activity'}>
                            <h2>Recent Activity</h2>
                            {this.props.user.children.length < this.props.user.allowed_children &&
                            <Link to="/add-child" onClick={this.props.extraProps.checkAddStudent}>Add a Student</Link>
                            }
                        </div>
                        }
                        {Object.keys(self.props.tests).length > 0 &&
                        <div className="parent-plan">
                            {self.props.user.children.map(function(child){
                                i++;
                                let lineData = [];
                                let assessCount = 0;
                                let has_digital_tutor = false;
                                let primaryTestName = "";
                                for (let test in child['tests']) {
                                    if (child['tests'].hasOwnProperty(test)) {
                                        if (self.props.tests.hasOwnProperty(child['tests'][test]['test']) &&
                                            self.props.tests[child['tests'][test]['test']].interactive_questions
                                        ) {
                                            has_digital_tutor = true;
                                        }
                                    }
                                    if (child.tests[test].primary === true) {
                                        primaryTestName = child.tests[test].test;
                                    }
                                }
                                let hasQuestions = false;
                                if (self.props.tests.hasOwnProperty(primaryTestName) &&
                                    self.props.tests[primaryTestName].interactive_questions === true) {
                                    hasQuestions = true;
                                }
                                return (
                                    <div className="plan-box" key={i}>
                                        <div className='top'>
                                            <h3>{child['name']}'s Work</h3>
                                            <Link to={'/dashboard/' + i + '/edit-child'}>Edit Profile <FontAwesomeIcon
                                                icon="cog"/></Link>
                                            <div className={'clear'}/>
                                        </div>
                                        <div className="details">
                                            {has_digital_tutor === true && hasQuestions === true &&
                                            <div>
                                                {
                                                    child.hasOwnProperty('assessments') &&
                                                    child['assessments'].length === 0 &&
                                                    self.props.user.paid_access &&
                                                    <div className={"noassess"}>
                                                        <p>{child['name']} hasn't taken any practice tests yet.</p><br/>
                                                        <br/>
                                                        <Link to={'/dashboard/' + i + '/practice'}><FontAwesomeIcon icon={['far', 'clipboard-list-check']} /> Begin Practice</Link>
                                                    </div>
                                                }
                                                {
                                                    child.hasOwnProperty('assessments') &&
                                                    child['assessments'].length === 0 &&
                                                    !self.props.user.paid_access &&
                                                    <div className={"loggedout"}>
                                                        <p>Buy now to access over 100,000 practice questions,
                                                            30+ skill building programs,
                                                            and exclusive content to help your child
                                                            ace their upcoming test
                                                            and achieve their academic goals or just to
                                                            unlock your <a href={WEBSITE_URL + "buy-now/?free-questions=1"}>
                                                                100 Free Practice Questions.</a></p>
                                                        <div className={'buy-now'}>
                                                            <a href={WEBSITE_URL + "buy-now/?free-questions=1"}>Buy Now</a>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    self.props.user.paid_access &&
                                                    child.hasOwnProperty('assessments') &&
                                                    child['assessments'].length > 0 &&
                                                    self.props.tests_by_id.hasOwnProperty(1) &&
                                                    <div>
                                                        <p>Last Three Practice Scores</p>
                                                        <div className={"scores"}>
                                                            {child['assessments'].slice().reverse().map(function (assess) {
                                                                if (assess['test'] !== null && typeof(assess['test']) !== 'undefined') {
                                                                    assessCount++;
                                                                    if (assessCount < 4) {
                                                                        let time = moment.utc(assess['created_at']).local().format('MM/DD');
                                                                        lineData.push({
                                                                            name: time,
                                                                            Score: assess['total_correct'] / assess['total_questions'] * 100
                                                                        });
                                                                        return (
                                                                            <div className="individual"
                                                                                 key={assess['id']}>
                                                                                <p className="date">{time}</p>
                                                                                <p className="activity">
                                                                        <span dangerouslySetInnerHTML={{
                                                                            __html: self.props.tests_by_id[assess['test']]['short_title']
                                                                        }}/> Practice <strong>
                                                                                    {assess['total_correct']}/{assess['total_questions']}
                                                                                </strong>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                                return false;
                                                            })}
                                                        </div>
                                                        <div className="chart">
                                                            <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                                                                 <LineChart data={lineData} margin={
                                                                     { top: 0, right: 20, left: 20, bottom: 25 }
                                                                 }>
                                                                    <Line type="linear" dataKey="Score"
                                                                          dot={{stroke: "#8884d8", strokeWidth: 3}}/>
                                                                    <XAxis dataKey="name">
                                                                        <Label value="Date" position="insideBottom" offset={-10}/>
                                                                   </XAxis>
                                                                    <YAxis domain={[0, 100]} minTickGap={25}>
                                                                        <Label value="% Correct" angle={-90} offset={0} position="left" style={{textAnchor: 'middle'}} />
                                                                    </YAxis>
                                                                    <Tooltip/>
                                                                </LineChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                        <div className="clear"/>
                                                    </div>
                                                }
                                            </div>
                                            }
                                            {
                                                child.tests.length > 0 &&
                                                has_digital_tutor === false &&
                                                <div className={'noassess'}>
                                                    <p>{child['name']} doesn't have any tests selected
                                                        with interactive practice questions.</p><br/>
                                                    <br/>
                                                    <p>Click settings above to add more tests or
                                                        click below to view documents, lessons, games, and more.</p>
                                                </div>
                                            }
                                            {
                                                child.tests.length === 0 &&
                                                <div className={'noassess'}>
                                                    <p>{child['name']} doesn't have any tests selected.</p><br/>
                                                    <br/>
                                                    <Link to={'/dashboard/' + i + '/edit-child'}>Click here to add tests</Link>
                                                </div>
                                            }
                                            {
                                                child.tests && child.tests.length > 0 &&
                                                hasQuestions === false &&
                                                    <div className={"noassess"}>
                                                        <p style={{marginBottom: "20px"}}>The primary test that you
                                                            have selected does not have interactive questions.</p>
                                                        <Link to={'/dashboard/' + i}><FontAwesomeIcon icon={'print'} /> Printable Practice</Link>
                                                    </div>

                                            }
                                            { has_digital_tutor &&
                                            <Link to={'/dashboard/' + i} className={"go-to-dashboard"}>
                                                Go to {child['name']}'s Dashboard
                                            </Link>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        }
                        {this.props.user.children.length > 0 && <div className={'headline'} />
                        }
                        <div className="site-content">
                            {this.props.user.children.length === 0 &&
                            <div className={"add-student content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"><FontAwesomeIcon icon={"plus-circle"}/></div>
                                    <p>Add Student</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Add a student to your profile to customize a test prep
                                        program</p>
                                    <img src={"/img/parent-dashboard/add-child.jpg"} alt={""} className={"pd-img"}/>
                                    <Link to={"/add-child"}>Add a student</Link>
                                </div>
                            </div>
                            }
                            <div className={"test-prep content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"/>
                                    <p>Test Prep</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Check out our test prep section with prep for over 40 tests for
                                        Gifted and Talented and 50 state tests</p>
                                    <img src={"/img/parent-dashboard/test-prep.jpg"} alt={""} className={"pd-img"} />
                                    <Link to={"/tests"}>Go to Test Prep Directory</Link>
                                </div>
                            </div>
                            {onlineClasses !== "noOnlineClasses" &&
                                <div className={'online-classes content-category'}>
                                    <div className="pd-header">
                                        <div className="pd-thumbnail"/>
                                        {this.props.extraProps.userClasses.noclasses === 1 &&
                                        <p>One-to-One Tutoring</p>
                                        }
                                        {this.props.extraProps.userClasses.noclasses !== 1 &&
                                        <p>Your One-to-One Tutoring</p>
                                        }
                                    </div>
                                    <div className={"pd-content"}>
                                        <div className={'tutoring'}>
                                            <div className={"thumbnail"}>
                                                <Link to={"https://testingmom.com/tutoring/"}>
                                                    <img src="//cdn.testingmom.com/img/homepage-tutoring.jpg" alt="" />
                                                </Link>
                                            </div>
                                            <Link to={"https://testingmom.com/tutoring/"}>
                                                Learn about our tutoring for test prep and academic subjects for pre-k to high school
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={"sba content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"/>
                                    <p>Interactive Learning Apps</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Interactive Learning Apps consist of 30+ partner
                                        programs from top educational providers which are a great resource for
                                        developing your students' academic skills</p>
                                    <img src={"/img/parent-dashboard/sba.jpg"} alt={""} className={"pd-img"} />
                                    <Link to={"/skill-building-academy"}>Go to Interactive Learning Apps</Link>
                                </div>
                            </div>
                            <div className={"games content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"/>
                                    <p>Online Games</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Play our original test prep and skill building games</p>
                                    <img src={"/img/parent-dashboard/games.jpg"} alt={""} className={"pd-img"} />
                                    <Link to={"/games"}>Go to Online Games</Link>
                                </div>
                            </div>
                            <div onClick={this.props.extraProps.showSiteTour} className={"site-tour content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"/>
                                    <p>Recorded Classes</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Instant access to recorded classes, test prep training,
                                    academic workshops, college prep, coding and tech classes, pre-school learning,
                                    fun electives, and more!</p>
                                    <img src={"/img/parent-dashboard/site_tour.jpg"} alt={""} className={"pd-img"} />
                                    <Link to={WEBSITE_URL + "classes/"}>Go to Recorded Classes</Link>
                                </div>
                            </div>
                            <div className={"parent-resources content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"/>
                                    <p>Parent Resources</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Our Parent Resources repository provides great tips on
                                        how to navigate your student's education and test preparation</p>
                                    <img src={"/img/parent-dashboard/parent-resources.jpg"} alt={""} className={"pd-img"} />
                                    <Link to={"/parent-resources"}>Go to Parent Resources</Link>
                                </div>
                            </div>
                            {this.props.user.children.length > 0 &&
                            <div className={"add-student content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"><FontAwesomeIcon icon={"plus-circle"}/></div>
                                    <p>Add Student</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>Add a student to your profile to customize a test prep
                                        program</p>
                                    <img src={"/img/parent-dashboard/add-child.jpg"} alt={""} className={"pd-img"}/>
                                    <Link to={"/add-child"}>Add a Student</Link>
                                </div>
                            </div>
                            }
                            <div onClick={this.props.extraProps.showSiteTour} className={"site-tour content-category"}>
                                <div className="pd-header">
                                    <div className="pd-thumbnail"/>
                                    <p>Site Tour</p>
                                </div>
                                <div className={"pd-content"}>
                                    <p className={"description"}>View our site tour to learn everything about the
                                        Testingmom.com program</p>
                                    <img src={"/img/parent-dashboard/site_tour.jpg"} alt={""} className={"pd-img"} />
                                    <Link to={"https://vimeo.com/380754676"}>Watch Site Tour</Link>
                                </div>
                            </div>
                        </div>
                        <img src={"/img/parent-dashboard/spacebabies-pd.png"} className={"spacebabies"} alt={""} />
                        {Object.keys(self.props.tests).length === 0 &&
                        <Loading/>
                        }
                    </div>
                    <Popup
                        contents={this.state.popUpContents}
                        hideCallback={function(){self.setState({popUpContents: ''})}}
                    />
                </div>
            </div>
        )
    }
}

export default ParentDashboard;
