import React, {Component} from 'react';
import Link from '../common/Link';
import './ChildSetup.css'
import {confirmAlert} from "react-confirm-alert";
import ReportedError from "../assessment/ReportedError";
import FreePractice from "../assessment/FreePractice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AddCategoryButton} from "../common/Buttons";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Buttons = require('../common/Buttons');
const AddTestButton = Buttons.AddTestButton;
const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;


function RecommendedTests(props) {
    if (props.grade) {
        let child = props.user.children[props.user.active_child];
        let region = props.regions[child.state];
        let tests = props.tests_by_grade[props.grade]['recommended'];
        if (region !== undefined && region !== null && region['recommended_tests'] && region['recommended_tests'].length > 0) {
            tests = region['recommended_tests'];
        } else {
            if (props.tests_by_grade.hasOwnProperty(props.grade - 1)) {
                tests = tests.concat(props.tests_by_grade[props.grade - 1]['recommended']);
            }
            if (props.tests_by_grade.hasOwnProperty(props.grade + 1)) {
                tests = tests.concat(props.tests_by_grade[props.grade + 1]['recommended']);
            }
        }
        tests = Array.from(new Set(tests));
        tests.sort(function (a, b) {
            if(a.short_title < b.short_title) { return -1; }
            if(a.short_title > b.short_title) { return 1; }
            return 0;
        });
        let i = 0;
        return (
            <ul>
                {tests.map(function(test){
                    i++;
                    let added = false;
                    for (let t in props.user.children[props.user.active_child].tests) {
                        if (props.user.children[props.user.active_child].tests[t].test === test.slug) {
                            added = true;
                        }
                    }
                    let active = added ? '' : 'active';
                    return <AddableTest
                        title={test['short_title']}
                        active={active}
                        setTest={props.setTest}
                        key={i}
                        test_id={test['slug']}
                        updatePrimaryTest={props.updatePrimaryTest}
                        primary={props.primary}
                    />
                })}
            </ul>
        )
    } else {
        return (
            <p className="instructions">Enter a grade and state above to load recommended tests.</p>
        )
    }
}

function AdditionalTests(props) {
    if (props.grade && props.user.active_child >= 0) {
        let child = props.user.children[props.user.active_child];
        let region = props.regions[child.state];
        let tests = props.tests_by_grade[props.grade]['all'].slice();
        let recommended = props.tests_by_grade[props.grade]['recommended'];
        if (region !== undefined && region !== null && region['recommended_tests'] && region['recommended_tests'].length > 0) {
            recommended = region['recommended_tests'];
        } else {
            if (props.tests_by_grade.hasOwnProperty(props.grade - 1)) {
                recommended = recommended.concat(props.tests_by_grade[props.grade - 1]['recommended']);
            }
            if (props.tests_by_grade.hasOwnProperty(props.grade + 1)) {
                recommended = recommended.concat(props.tests_by_grade[props.grade + 1]['recommended']);
            }
            recommended = Array.from(new Set(recommended));
        }

        if (tests.length && recommended.length) {
            for (let test in tests) {
                if (tests.hasOwnProperty(test)) {
                    for (let rec in recommended) {
                        if (recommended.hasOwnProperty(rec)) {
                            if (tests[test] && recommended[rec] && tests[test].title === recommended[rec].title) {
                                tests.splice(test, 1);
                            }
                        }
                    }
                }
            }
        }

        tests = Array.from(new Set(tests));
        tests.sort(function (a, b) {
            if (a.short_title.toLowerCase() < b.short_title.toLowerCase()) { return -1; }
            if (a.short_title.toLowerCase() > b.short_title.toLowerCase()) { return 1; }
            return 0;
        });

        let i = 0;
        return (
            <ul id="test-list">
                {tests.map(function(test){
                    i++;
                    let added = false;
                    for (let t in props.user.children[props.user.active_child].tests) {
                        if (props.user.children[props.user.active_child].tests[t].test === test.slug) {
                            added = true;
                        }
                    }
                    let active = added ? '' : 'active';
                    if (test['usable_in_programs']) {
                        return <AddableTest
                            title={test['short_title']}
                            active={active}
                            setTest={props.setTest}
                            key={i}
                            test_id={test['slug']}
                            updatePrimaryTest={props.updatePrimaryTest}
                            primary={props.primary}
                        />
                    } else {
                        return false;
                    }
                })}
            </ul>
        )
    } else {
        return (
            <p className="instructions">Enter a grade and state above to load recommended tests.</p>
        )
    }
}

function AddableTest(props) {
    return (
        <li className={"add-list-item"}>
            <AddTestButton active={props.active} test_id={props.test_id} primary={props.primary} title={props.title}
                       clickStar={function() {props.updatePrimaryTest(props.test_id)}}
                       clickCheck={function() {props.setTest(props.test_id)}}/>
        </li>
    );
}

function AddableCategory(props) {
    return (
        <li className={"add-list-item"}>
            <AddCategoryButton active={props.active} cat_id={props.id} title={props.title}
                               clickCheck={function() {props.setClassCategory(props.id)}}
            />
        </li>
    );
}

function OnlineClassCategories(props) {
    if (props.onlineClassCategories) {
        let i = 0;
        return (
            <div className={"test-box classes"}>
                <ul>
                    {props.onlineClassCategories.map(function(cat){
                        i++;
                        let active = false;
                        if (props.child.class_categories.includes(cat.id)) {
                            active = true;
                        }
                        return <AddableCategory active={active} id={cat.id} title={cat.title} key={i}
                                                setClassCategory={props.setClassCategory}/>
                    })}
                </ul>
            </div>
        )
    } else {
        return (
            <p className="instructions">Enter a grade and state above to load recommended tests.</p>
        )
    }
}

class ChildSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'grades': [],
            'country': 'USA',
            'tests': [],
            'loaded': false,
            'child_id': 0,
            'child_name': '',
            'grade_id': '',
            'state_id': '',
            'addChild': this.props.extraProps.addChild,
            'editChild': this.props.extraProps.editChild,
            'firstChild': this.props.extraProps.firstChild,
            'showFreePractice': false,
            'displayedSection': "profile",
            'noSetUp': false
        };

        this.has_child = false;
        this.loaded = false;
        this.addStudent = this.addStudent.bind(this);
        this.changeSection = this.changeSection.bind(this);
        this.deleteChild = this.deleteChild.bind(this);
        this.gradeChange = this.gradeChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.regionChange = this.regionChange.bind(this);
        this.saveChild = this.saveChild.bind(this);
        this.setActiveUser = this.setActiveUser.bind(this);
        this.setChildData = this.setChildData.bind(this);
        this.setClassCategory = this.setClassCategory.bind(this);
        this.setTest = this.setTest.bind(this);
        this.showFreeQuickPractice = this.showFreeQuickPractice.bind(this);
        this.updatePrimaryTest = this.updatePrimaryTest.bind(this);
    }

    componentDidUpdate() {
        let self = this;
        if (Object.keys(this.props.grades).length && Object.keys(this.props.regions).length && !this.state.loaded) {
            this.setChildData();
        }
        if (this.state.loaded && parseInt(this.props.user.active_child) > -1 && this.props.extraProps.addChild && !this.loaded) {
            this.setState({
                addChild: true,
                editChild: false,
                firstChild: false,
                child_id: 0,
            }, this.setChildData);
            this.has_child = false;
        } else if (
            this.state.addChild !== this.props.extraProps.addChild ||
            this.state.firstChild !== this.props.extraProps.firstChild ||
            this.state.editChild !== this.props.extraProps.editChild
        ) {
            this.setState({
                'addChild': this.props.extraProps.addChild,
                'editChild': this.props.extraProps.editChild,
                'firstChild': this.props.extraProps.firstChild,
            }, function(){
                if (self.props.extraProps.editChild) {
                    self.setChildData();
                }
            });
        } else if (this.state.child_id === 0 && this.props.user.active_child >= 0) {
            self.setChildData();
        }

        if (this.props.user.active_child >= 0 && this.props.user.children[this.props.user.active_child].tests.length) {
            let tests = this.props.user.children[this.props.user.active_child].tests;
            if (tests) {
                for (let i = tests.length; i--;) {
                    if (tests[i].primary) {
                        let primaryTest = document.querySelector('.primaryBtn.' + tests[i].test);
                        if (primaryTest) {
                            primaryTest.classList.add('active');
                            if (primaryTest.classList.contains('active')) {
                            }
                        }
                    }
                }
            }
        } else if (typeof this.props.appProps.match.params.child_id !== 'undefined' &&
            parseInt(this.props.appProps.match.params.child_id) !== parseInt(this.props.user.active_child)) {
            this.setActiveUser()
        }
    }

    componentDidMount() {
        if (this.state.child_name &&
            this.state.child_id &&
            this.props.user.children.hasOwnProperty(this.props.user.active_child) &&
            this.props.user.children[this.props.user.active_child].grade !== null &&
            this.props.user.children[this.props.user.active_child].name !== '' &&
            this.props.user.children[this.props.user.active_child].state !== null) {
            this.setState({
                displayedSection: "testing"
            })
        }

        if (!this.props.user.children.length) {
            this.setState({
                displayedSection: "profile",
            })
        }
    }

    componentWillMount() {
        if (Object.keys(this.props.grades).length && Object.keys(this.props.regions).length) {
            this.setChildData();
        }
    }

    setActiveUser() {
        if (typeof this.props.appProps.match.params.child_id !== 'undefined' &&
            parseInt(this.props.appProps.match.params.child_id) !== parseInt(this.props.user.active_child) &&
            this.props.appProps.match.params.child_id <= this.props.user.children.length - 1
        ) {
            let active_test = null;
            for (let t in this.props.user.children[this.props.appProps.match.params.child_id]['tests']) {
                if (this.props.user.children[this.props.appProps.match.params.child_id]['tests'].hasOwnProperty(t) &&
                    this.props.user.children[this.props.appProps.match.params.child_id]['tests'][t]['primary'] &&
                    this.props.tests.hasOwnProperty(
                        this.props.user.children[this.props.appProps.match.params.child_id]['tests'][t]['test']
                    )
                ) {
                    active_test = this.props.tests[
                        this.props.user.children[this.props.appProps.match.params.child_id]['tests'][t]['test']
                    ];
                }
            }
            let data = {
                active_child: this.props.appProps.match.params.child_id
            };
            if (active_test) {
                data['active_test'] = active_test;
            }
            this.props.updateUser(
                data,
                this.props.extraProps.setChildAssessmentsByTest
            );
        }
        if (this.state.addChild) {
            this.setState({
                child_name: '',
                grade_id: '',
                state_id: ''
            })
        }
    }

    setChildData()
    {
        if (this.props.extraProps.addChild && this.props.user.active_child !== -1 && !this.loaded) {
            this.props.updateUser({active_child: -1}, this.setActiveUser);
            this.loaded = true;
        } else if (!this.loaded) {
            let self = this;
            if (typeof this.props.appProps.match.params.child_id !== 'undefined' &&
                parseInt(this.props.appProps.match.params.child_id) !== parseInt(this.props.user.active_child)) {
                this.setActiveUser();
            } else {
                let child_id = this.state.child_id;
                if (self.props.user.children.length && self.props.user.active_child >= 0) {
                    child_id = self.props.user.children[self.props.user.active_child]['id'];
                    self.has_child = true;
                }
                let child_name = '';
                let grade_id = '';
                let state_id = '';
                let user = this.props.user;

                if (user.children.length && user.active_child >= 0 && user.children[user.active_child].name.length) {
                    child_name = user.children[user.active_child].name;
                    grade_id = user.children[user.active_child].grade;
                    state_id = user.children[user.active_child].state;
                    if (grade_id === null) {
                        grade_id = '';
                    }
                    if (state_id === null) {
                        state_id = '';
                    }
                }
                let data = {
                    child_name: child_name,
                    grade_id: grade_id,
                    state_id: state_id,
                    loaded: true,
                    child_id: child_id
                };
                this.loaded = true;
                this.setState(data);
            }
        }
    }

    deleteChild() {
        let self = this;

        this.props.extraProps.deleteChild(
            this.state.child_id,
            this.state.child_name,
            this.props.user,
            this.props.appProps.history,
            function() {
                self.setState({
                    child_id: 0,
                    child_name: '',
                    grade_id: '',
                    state_id: '',
                }, function(){
                    self.has_child = false;
                });
                if (self.props.user.children.length <= 1) {
                    self.setState({
                        displayedSection: 'profile'
                    });
                }
            }
        );
    }

    saveChild() {
        if (this.state.child_name) {
            let self = this;
            let child_name = document.querySelector('input[name="child-name"]').value;
            let grade = document.querySelector('select[name="grade"]').value;
            let state = document.querySelector('select[name="state"]').value;
            if (self.has_child) {
                let data = {
                    'name': child_name,
                    'grade': grade,
                    'state': state
                };
                this.props.updateChild(self.state.child_id, data);
                this.setState({
                    'child_name': child_name,
                    'grade_id': grade,
                    'state_id': state,
                });
            } else {
                ApiFetch('user-child/', 'POST', {
                    'name': child_name,
                    'grade': grade,
                    'state': state,
                    'active': true
                }).then(function (response) {
                    let userData = Object.assign({}, self.props.user);
                    if (userData['children'].length) {
                        for (let c in userData['children']) {
                            if (userData['children'].hasOwnProperty(c)) {
                                userData['children'][c]['active'] = false;
                            }
                        }
                    }
                    userData['children'].push(response);
                    userData['active_child'] = userData['children'].length - 1;
                    userData.active_child = userData.children.length - 1;
                    self.props.updateUser(userData);
                    self.has_child = true;
                    let grade_id = (response['grade'] === null) ? '' : response['grade'];
                    let state_id = (response['state'] === null) ? '' : response['state'];
                    self.setState({
                        child_name: response['name'],
                        grade_id: grade_id,
                        state_id: state_id,
                        child_id: response['id']
                    });
                    self.props.appProps.history.push('/dashboard/' + String(userData['children'].length - 1) + '/edit-child/')
                });
            }
        }
    }

    gradeChange(e) {
        this.setState({
            'grade_id': e.target.value
        });
        this.saveChild();
    }

    regionChange(e)
    {
        this.setState({
            'state_id': e.target.value
        });
        this.saveChild();
    }

    showFreeQuickPractice()
    {
        // if (this.state.showFreePractice === false) {
        //     this.setState({
        //         'showFreePractice': true
        //     })
        // } else {
        //     this.setState({
        //         'showFreePractice': false
        //     })
        // }
        window.location.href = WEBSITE_URL + 'buy-now/?free-questions=1';
    }

    setClassCategory(c) {
        this.props.updateChild(this.props.user.children[this.props.user.active_child].id, {'classes': [c]});
    }

    setTest(test)
    {
        let children = this.props.user.children;
        let child = children[this.props.user.active_child];
        let tests = child.tests;
        let hasTest = false;
        let is_primary = false;
        let checkbox = document.querySelector('.test-box.tests .checkbox.' + test);
        if (checkbox.classList.contains('active')) {
            //Adds test to student profile
            for (let t in tests) {
                if (tests.hasOwnProperty(t)) {
                    if (tests[t].test === test) {
                        hasTest = true;
                    }
                }
            }
            if (tests.length === 0) {
                this.props.updateUser({'active_test': this.props.tests[test]});
            }
            if (tests.length <= 4) {
                if (!hasTest) {
                    let primary = false;
                    if (tests.length === 0) {
                        primary = true;
                    }
                    tests.push({'test': test, 'primary': primary});
                    let data = {'tests': tests};
                    this.props.updateChild(child.id, data);
                }
            } else {
                confirmAlert({
                    message: 'You are only allowed to select 5 tests.  Please remove a selected test from the list before' +
                        ' you can add another test.',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: function () {
                                document.documentElement.style.overflowY = 'auto';
                            }
                        },
                    ]
                });
            }
        } else {
            //Removes test from student profile
            for (let t in tests) {
                if (tests.hasOwnProperty(t)) {
                    if (tests[t]['test'] === test) {
                        if (tests[t]['primary'] === true) {
                            is_primary = true;
                        }
                        tests.splice(t, 1);
                    }
                }
            }
            if (tests.length === 1) {
                checkbox.checked = false;
            }
            // Set first test to primary test if we are removing the primary
            if (tests.length > 0 && is_primary) {
                tests[0]['primary'] = true;
            }
            let data = {'tests': tests};
            this.props.updateChild(child.id, data);
        }
    }

    updatePrimaryTest(test) {
        let children = this.props.user.children;
        let child = children[this.props.user.active_child];
        let tests = child.tests;
        for (let t in tests) {
            if (tests.hasOwnProperty(t)) {
                tests[t]['primary'] = (tests[t]['test'] === test);
            }
        }
        let data = {'tests': tests};
        this.props.updateChild(child.id, data);

        let primaryTest = "";
        if (this.props.user.active_child !== -1) {
            for (let p in this.props.user.children[this.props.user.active_child].tests) {
                if (this.props.user.children[this.props.user.active_child].tests[p].primary === true) {
                    primaryTest = this.props.tests[this.props.user.children[this.props.user.active_child].tests[p].test];
                }
            }
        }
        this.props.updateUser({'active_test': primaryTest});
        let allTestBtns = document.querySelectorAll('.test-box.tests button.primaryBtn');
        if (allTestBtns) {
            for (let a = allTestBtns.length; a--;) {
                if (allTestBtns[a].classList.contains('active')) {
                    allTestBtns[a].classList.remove('active');
                }
            }
        }
        let selectedTestBtn = document.querySelector('.test-box,tests button.primaryBtn.' + test);
        if (selectedTestBtn) {
            selectedTestBtn.classList.add('active');
        }
    }

    addStudent() {
        this.props.extraProps.checkAddStudent();
        if (this.props.user.children.length < this.props.user.allowed_children) {
            this.props.updateUser({active_child: -1});
            this.setState({
                'child_name': '',
                'grade_id': '',
                'state_id': '',
                'displayedSection': 'profile'
            });
            this.has_child = false;
        }
    }

    handleNameChange(e) {
        this.setState({child_name: e.target.value});
        if (this.nameChangeTO) {
            clearTimeout(this.nameChangeTO);
        }
        this.nameChangeTO = setTimeout(this.saveChild, 1000);
    }

    changeSection(section) {
        if (section === "profile" || (section === "testing" && (this.state.child_name &&
            this.state.child_id &&
            this.props.user.children.hasOwnProperty(this.props.user.active_child) &&
            this.props.user.children[this.props.user.active_child].grade !== null &&
            this.props.user.children[this.props.user.active_child].name !== '' &&
            this.props.user.children[this.props.user.active_child].state !== null))) {
            this.setState({
                displayedSection: section,
                noSetUp: false
            })
        } else {
            this.setState({
                noSetUp: true
            });
        }
    }

    render () {
        let self = this;
        let i = 0;
        if (Object.keys(this.props.grades).length && Object.keys(this.props.regions).length) {
            let user = this.props.user;
            let h1 = 'Add a Student';
            if (this.state.editChild && user.children.hasOwnProperty(user.active_child) &&
                this.state.displayedSection === "profile") {
                h1 = 'Edit ' + user.children[user.active_child].name + '\'s Info';
            }
            if (this.state.editChild && user.children.hasOwnProperty(user.active_child) &&
                this.state.displayedSection === "testing") {
                h1 = 'Edit ' + user.children[user.active_child].name + '\'s Tests';
            }

            let testsActive = false;
            if (this.state.child_name &&
                this.state.child_id &&
                this.props.user.children.hasOwnProperty(this.props.user.active_child) &&
                this.props.user.children[this.props.user.active_child].grade !== null &&
                this.props.user.children[this.props.user.active_child].name !== '' &&
                this.props.user.children[this.props.user.active_child].state !== null
            ) {
                testsActive = true;
            }

            let backToDashboard = '/';
            if (this.props.user.active_child) {
                backToDashboard = '/dashboard/' + this.props.user.active_child;
            }

            if (this.props.user.children.length > 4 && this.state.addChild && !this.has_child) {
                return (
                    <div id="test-dashboard" className="wrapper">
                        <div className="wrapper get-started">
                            <div className="dashboard">
                                <Link className={"back-to-dash"} to={ backToDashboard }>
                                    <FontAwesomeIcon icon={'arrow-circle-left'} />Back to Dashboard</Link>
                                <h1 style={{paddingTop: "30px"}}>You have reached the maximum number of students per account.
                                    Please remove an existing student in order to add a new student.</h1>
                            </div>
                        </div>
                    </div>
                )
            } else {
                let access = "";
                if (!user.paid_access) {
                    access = "noAccess"
                }

                let addChild = "";
                if (this.state.addChild && !this.state.firstChild) {
                    addChild = " addChild";
                }
                let primaryTestName = "";
                if (this.props.user.children.length && this.props.user.active_child !== -1) {
                    for (let p in this.props.user.children[this.props.user.active_child].tests) {
                        if (this.props.user.children[this.props.user.active_child].tests[p].primary === true) {
                            primaryTestName = this.props.user.children[this.props.user.active_child].tests[p].test;
                        }
                    }
                }
                let hasQuestions = false;
                if (this.props.tests.hasOwnProperty(primaryTestName)) {
                    if (this.props.tests[primaryTestName].interactive_questions !== null) {
                        if (this.props.tests[primaryTestName].interactive_questions === true) {
                            hasQuestions = true;
                        }
                    }
                }

                let primaryTest = "";
                if (this.props.user.active_child >= 0 && this.props.user.children[this.props.user.active_child].tests.length) {
                    for (let i = this.props.user.children[this.props.user.active_child].tests.length; i--;) {
                        if (this.props.user.children[this.props.user.active_child].tests[i].primary) {
                            primaryTest = this.props.user.children[this.props.user.active_child].tests[i].test;
                        }
                    }
                }

                let setupChildClass = "editChild";
                if (this.state.displayedSection === "profile") {
                    setupChildClass = "setUpChild";
                }

                return (
                    <div id="test-dashboard" className="wrapper">
                        <div className={"get-started "+ setupChildClass}>
                            <div className="dashboard">
                                {this.state.displayedSection === "testing" && this.props.user.children.length &&
                                <span onClick={() => {this.changeSection('profile')}} className={'last delete-child'} >
                                    <FontAwesomeIcon icon={'info-circle'} /> Edit {this.state.child_name}
                                    's Grade/State
                                </span>
                                }
                                {this.props.extraProps.editChild === true &&
                                <span className={"delete-child"} onClick={self.deleteChild}>
                                    <FontAwesomeIcon icon={['far', 'trash-alt']} /> Remove Student
                                </span>
                                }
                                <h1>{h1}</h1>
                                {
                                    self.props.extraProps.base_price > 0 &&
                                    <div>
                                        <p style={{textAlign: 'center'}}>
                                            Access over 100,000 practice questions, 30+ skill building programs,
                                            and exclusive content to help your child ace their upcoming test
                                            and achieve their academic goals. <strong>
                                            Our Fast Tack Membership starts at $8.25/month.</strong></p>
                                        <p style={{textAlign: 'center', marginTop: '30px'}}>
                                            <a href={WEBSITE_URL + 'buy-now/'} className={"buy-now"}>
                                                <span style={{padding: '7px 30px'}}>Buy Now</span>
                                            </a>
                                        </p>
                                    </div>
                                }
                                {this.state.displayedSection === "profile" &&
                                <div className={"profile-info "}>
                                    <img src="/img/test-dashboard/icon-welcome-page.png" alt=""
                                         className="heading-img"/>
                                    <h3 className='profile-info-heading'>Profile Information</h3>
                                    <p className='profile-info-subtitle'><strong>Step 1: </strong>
                                        Enter your student’s information below.
                                        You can always change the student's information later.</p>
                                    <div className="name-input">
                                        <label htmlFor="name">Student's Name</label>
                                        <input name="child-name" id="name" type="text"
                                               placeholder="Enter Your Student's Name" value={this.state.child_name}
                                               onChange={this.handleNameChange}/>
                                    </div>
                                    <div className="select-contain">
                                        <label htmlFor="grade">Grade</label>
                                        <div className="styled-select">
                                            <select
                                                name="grade"
                                                id="grade"
                                                value={this.state.grade_id}
                                                onChange={this.gradeChange}>
                                                <option value="" disabled>---</option>
                                                {
                                                    Object.keys(self.props.grades).map(function (grade) {
                                                        i++;
                                                        return <option value={self.props.grades[grade]['id']}
                                                                       key={i}>{self.props.grades[grade].name}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="select-contain">
                                        <label htmlFor="state">Testing State</label>
                                        <div className="styled-select">
                                            <select
                                                name="state"
                                                id="state"
                                                value={this.state.state_id}
                                                onChange={this.regionChange}
                                            >
                                                <option disabled value="">---</option>
                                                {
                                                    Object.keys(this.props.regions).map(function (key) {
                                                        i++;
                                                        return <option value={self.props.regions[key]['id']}
                                                                       key={i}>{self.props.regions[key]['name']}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className={"change-container"}>
                                        <button onClick={() => {
                                            this.changeSection('testing')
                                        }}
                                                className={'change-section next'}>Set Up Tests
                                        </button>
                                        <div className={"clear"} />
                                        {this.state.noSetUp &&
                                        <span className={"noSetUp"}>Please enter all student information before
                                            proceeding.</span>
                                        }
                                    </div>
                                    {user.paid_access &&
                                    (typeof (self.props.user.children[this.props.user.active_child]) === 'undefined' ||
                                        this.props.user.children[this.props.user.active_child].tests.length === 0) &&
                                        <div className={"btn-container"} style={{marginTop: "10px"}}>
                                            <p className="grey-btn btn marginR"
                                               onClick={this.addStudent}>
                                                <FontAwesomeIcon icon={'user-circle'}/>&nbsp;
                                                Add Another Student</p>
                                            <p className="grey-btn btn marginR">
                                                <FontAwesomeIcon icon={'clipboard-list-check'}/>
                                                Begin Practice</p>
                                            <p className="grey-btn btn">
                                                <FontAwesomeIcon icon={'print'}/>
                                                Go to Printables</p>
                                        </div>
                                    }
                                    <img src="/img/test-dashboard/lessons-babies.jpg" alt="" className={"spacebaby"} />
                                </div>
                                }
                                <div className="clear"/>
                                {this.state.displayedSection === "testing" && this.props.user.children.length &&
                                <div className="testing-info ">
                                    {this.props.user.active_child !== -1 &&
                                    typeof (self.props.user.children) !== 'undefined' &&
                                    typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                    this.props.user.children[this.props.user.active_child].grade &&
                                    this.props.grades_by_id[this.props.user.children[this.props.user.active_child].grade].hasOwnProperty('numeric') &&
                                    this.props.grades_by_id[this.props.user.children[this.props.user.active_child].grade].numeric >= 2 &&
                                    this.props.user.children[this.props.user.active_child].state !== null &&
                                    this.props.regions[this.props.user.children[this.props.user.active_child].state].country !== null &&
                                    <Link className="state-link" to={"/test-prep/states-common-core/" +
                                    this.props.regions[this.props.user.children[this.props.user.active_child].state].name}>
                                        Go
                                        to {this.props.regions[this.props.user.children[this.props.user.active_child].state].name} State
                                        Testing</Link>
                                    }
                                    <h3>Testing Information</h3>
                                    <p>Choose up to 5 tests for your student to study.
                                        You can always change which tests are selected later. Select a primary test by
                                        clicking a star; this may be a challenging test or the test with a rapidly
                                        approaching date.</p>
                                    <div className="legend">
                                        <div className="star-legend">
                                            <FontAwesomeIcon icon={["fas", "star"]} />
                                            <span>= Primary Test</span>
                                        </div>
                                        <div className="star-legend">
                                            <FontAwesomeIcon icon={["far", "star"]} />
                                            <span>= Non-Primary Test</span>
                                        </div>
                                    </div>
                                    <div className="test-box tests">
                                        <div className={"heading-row"}>
                                            <p className="heading">Most Popular Tests in {
                                                this.props.regions[this.props.user.children[this.props.user.active_child].state].name}
                                            </p>
                                        </div>
                                        {self.has_child &&
                                        typeof (self.props.user.children) !== 'undefined' &&
                                        typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                        self.props.user.active_child >= 0 &&
                                        <RecommendedTests
                                            grade={user.children[user.active_child].grade}
                                            tests_by_grade={this.props.tests_by_grade}
                                            setTest={self.setTest}
                                            user={self.props.user}
                                            regions={self.props.regions}
                                            updatePrimaryTest={self.updatePrimaryTest}
                                            primary={primaryTest}
                                        />
                                        }
                                        <div className={"heading-row"}>
                                            <p className="heading additional">Additional Tests</p>
                                        </div>
                                        {self.has_child &&
                                        typeof (self.props.user.children) !== 'undefined' &&
                                        typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                        self.props.user.active_child >= 0 &&
                                        <AdditionalTests
                                            grade={user.children[user.active_child].grade}
                                            tests_by_grade={this.props.tests_by_grade}
                                            setTest={self.setTest}
                                            regions={self.props.regions}
                                            user={self.props.user}
                                            updatePrimaryTest={self.updatePrimaryTest}
                                            primary={primaryTest}
                                        />
                                        }
                                    </div>
                                    <div className="clear"/>
                                </div>
                                }
                                {!user.paid_access &&
                                <div>
                                    <p style={{textAlign: 'center'}}>
                                        Click on the Buy Now button below to take a look
                                        at our membership options and prices! </p>
                                    <div className={"btns buy_now " + access + addChild}>
                                        <div className="flex50">
                                            {
                                                (
                                                    (this.props.user.active_child > -1 &&
                                                        typeof (self.props.user.children) !== 'undefined' &&
                                                        typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                                        this.props.user.children[this.props.user.active_child].tests.length === 0
                                                    )
                                                    || hasQuestions === false
                                                ) &&
                                                <div className={"btn-container"}>
                                                    <p className="grey-btn btn">
                                                        <FontAwesomeIcon icon={'clipboard-list-check'}/>
                                                        Begin Practice</p>
                                                    <p className="grey-btn btn">
                                                        <FontAwesomeIcon icon={'print'}/>
                                                        Go to Printables</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex50">
                                            <a href={WEBSITE_URL + 'buy-now/'} className="btn buy-now">
                                                <img src='/img/test-dashboard/button-buy-now.png' alt="Buy Now"/>
                                            </a>
                                        </div>
                                        <div className="clear"/>
                                    </div>
                                </div>
                                }
                                {user.paid_access && (this.state.displayedSection === "testing" ||
                                    (!this.state.addChild && testsActive === true &&
                                        this.props.user.children[this.props.user.active_child].tests.length > 0)) &&
                                <div className={"btns " + access}>
                                    {
                                        this.props.user.paid_access &&
                                        this.props.user.active_child > -1 &&
                                        typeof (self.props.user.children) !== 'undefined' &&
                                        typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                        this.props.user.children[this.props.user.active_child].tests.length > 0 &&
                                        <div className={"btn-container"}>
                                            {this.props.user.children.length < 5 && !this.props.extraProps.addChild &&
                                            <Link to={"/add-child"} className="blue-btn btn marginR"
                                                  onClick={this.addStudent}>
                                                <FontAwesomeIcon icon={'user-circle'}/>&nbsp;
                                                Add Another Student</Link>
                                            }
                                            {hasQuestions === true &&
                                            <Link to={"/dashboard/" + this.props.user.active_child + '/practice'}
                                                  className="blue-btn btn marginR">
                                                <FontAwesomeIcon icon={['far', 'clipboard-list-check']}/>
                                                Begin Practice</Link>
                                            }
                                            <Link
                                                to={"/dashboard/" + this.props.user.active_child + '/printables'}
                                                className="blue-btn btn">
                                                <FontAwesomeIcon icon={'print'}/>
                                                Go to Printables</Link>
                                        </div>
                                    }
                                    {
                                        !this.props.user.paid_access &&
                                        this.props.user.active_child > -1 &&
                                        typeof (self.props.user.children) !== 'undefined' &&
                                        typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                        this.props.user.children[this.props.user.active_child].tests.length > 0 &&
                                        <div className={"btn-container"}>
                                            {this.props.user.children.length < 5 && !this.props.extraProps.addChild &&
                                            <Link to={"/add-child"} className="blue-btn btn marginR"
                                                  onClick={this.addStudent}>
                                                <FontAwesomeIcon icon={'user-circle'}/>&nbsp;
                                                Add Another Student</Link>
                                            }
                                            {hasQuestions === true &&
                                            <Link to={"/dashboard/" + this.props.user.active_child + '/'}
                                                  className="grey-btn btn marginR">
                                                Begin Practice</Link>
                                            }
                                            <Link
                                                to={"/test-prep/" + this.props.user.active_test.slug + "/practice-questions"}
                                                className="blue-btn btn">
                                                Go to Printables</Link>
                                        </div>
                                    }
                                    {
                                        (
                                            this.props.user.active_child === -1 ||
                                            (this.props.user.active_child > -1 &&
                                                typeof (self.props.user.children) !== 'undefined' &&
                                                typeof (self.props.user.children[this.props.user.active_child]) !== 'undefined' &&
                                                this.props.user.children[this.props.user.active_child].tests.length === 0
                                            )
                                        ) &&
                                        <div className={"btn-container"}>
                                            <p className="grey-btn btn marginR">
                                                <FontAwesomeIcon icon={'clipboard-list-check'}/>
                                                Begin Practice</p>
                                            <p className="grey-btn btn">
                                                <FontAwesomeIcon icon={'print'}/>
                                                Go to Printables</p>
                                            {this.state.addChild && !this.state.firstChild &&
                                            <Link to="/" className="btn red-btn">Cancel</Link>
                                            }
                                        </div>
                                    }
                                </div>
                                }
                                <div className="clear"/>
                            </div>
                            {!this.props.user.paid_access && this.state.child_name && this.state.grade_id && this.state.showFreePractice &&
                            <FreePractice
                                key={i}
                                child_name={this.state.child_name}
                                grade_id={this.state.grade_id}
                                grades={this.props.grades}
                                grades_by_id={this.props.grades_by_id}
                                history={this.props.appProps.history}
                                number_of_questions={10}
                                setChildAssessmentsByTest={this.props.extraProps.setChildAssessmentsByTest}
                                showFreePractice={this.showFreeQuickPractice}
                                skill_id={this.props.appProps.match.params.skill_id}
                                updateUser={this.props.updateUser}
                                user={this.props.user}
                            />
                            }
                            <ReportedError
                            />
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <Loading />
            );
        }
    }
}

export default ChildSetup;